import ApiService from '@/common/api.service'

import { getField, updateField } from 'vuex-map-fields';
import { getDataVersion } from '../utils'

/* eslint-disable */
const getDefaultState = () => {
  return {
    ancientProxForm: {
      id: 0,
      isActive: true,
      code: null,
      title: null,
      name: null,
      desciption1: null,
      desciption2: null,
      desciption3: null,
      research: null,
      date: null,
      location: null,
      samples: null,
      yDna: null,
      mtDna: null,
      pictureUrl: null,
      sourceCodes: null,
      logoUrl: null,
      dataVersion: null,
    },
  }
}

const state = getDefaultState()

const getters = {
  getAncientField(state) {
    return getField(state.ancientProxForm);
  },
  getAncientForm(state) {
    return state.ancientProxForm;
  },
}

const mutations = { 
  updateAncientField(state, field) {
    updateField(state.ancientProxForm, field);
  },
  setAncientField(state, data) {
    state.ancientProxForm = data;
  },
  resetAncientField(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchAncientProxs({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(rootState)
      ApiService.get('AncientProxData/pagination', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchAncientProx({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`AncientProxData/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveAncientProx({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(rootState)
      ApiService.post('AncientProxData', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeAncientProx({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`AncientProxData/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
