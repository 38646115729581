import ApiService from '@/common/api.service'
import { getDataVersion } from '../utils'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  fetchSourceDatas({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(rootState)
      ApiService.get('SourceData/pagination', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchSourceDatasByType({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(rootState)
      queryParams.sourceDataType = queryParams.type
      ApiService.get('SourceData', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchSourceData({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`SourceData/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  saveSourceData({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(rootState)
      ApiService.post('SourceData', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removeSourceData({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`SourceData/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
