import ApiService from '@/common/api.service'
import { getDataVersion } from '../utils'

/* eslint-disable */
const getDefaultState = () => {
  return {
    presetRegionGrupList: [],
    pcaRegionGrupList: [],
  }
}

const state = getDefaultState()

const getters = {
  getPresetRegionGrupList(state) {
    return state.presetRegionGrupList;
  },

  getPcaRegionGrupList(state) {
    return state.pcaRegionGrupList;
  },
}

const mutations = {
  setPresetRegionGrupList(state, list) {
    state.presetRegionGrupList = list;
  },

  setPcaRegionGrupList(state, list) {
    state.pcaRegionGrupList = list;
  },
}

const actions = {
  fetchPresetRegionGrupList(ctx, queryParams) {
    queryParams.presetRegionGrupType = 1;
    queryParams.dataVersion = getDataVersion(ctx.rootState)

    return ApiService.get('presetRegiongrup', queryParams, this)
    .then(response => {
      if (response) {
        ctx.commit('setPresetRegionGrupList', response.result);
      }
    });
  },

  fetchPresetRegionPcaGrupList(ctx, queryParams) {
    queryParams.presetRegionGrupType = 2;
    queryParams.dataVersion = getDataVersion(ctx.rootState)

    return ApiService.get('PresetRegionGrup', queryParams, this)
    .then(response => {
      if (response) {
        ctx.commit('setPcaRegionGrupList', response.result);
      }
    });
  },

  fetchPresetRegionGrups(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(ctx.rootState)
      ApiService.get('PresetRegionGrup/pagination', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchPresetRegionGrup(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetRegionGrup/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  savePresetRegionGrup(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(ctx.rootState)
      ApiService.post('PresetRegionGrup', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removePresetRegionGrup(context, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`PresetRegionGrup/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
