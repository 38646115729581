import ApiService from '@/common/api.service'

import { getField, updateField } from 'vuex-map-fields';
import { getDataVersion } from '../utils'

/* eslint-disable */
const getDefaultState = () => {
  return {
    presetFarmersHGsForm: {
      id: 0,
      isActive: true,
      code: null,
      title: null,
      description: null,
      supplementaryInfo: null,
      referenceSamples: null,
      researchLinks: null,
      sourceCodes: null,
      pictureUrl: null,
      logoUrl: null,
      color: null,
      dataVersion: null,
    },
  }
}

const state = getDefaultState()

const getters = {
  getFarmersHgField(state) {
    return getField(state.presetFarmersHGsForm);
  },
  getFarmersHgForm(state) {
    return state.presetFarmersHGsForm;
  },
}

const mutations = { 
  updateFarmersHgField(state, field) {
    updateField(state.presetFarmersHGsForm, field);
  },
  setFarmersHgField(state, data) {
    state.presetFarmersHGsForm = data;
  },
  resetFarmersHgField(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  fetchPresetFarmersHgs({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(rootState)
      ApiService.get('PresetFarmerAndHGs/pagination', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchPresetFarmersHg({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.get(`PresetFarmerAndHGs/${queryParams.id}`, queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  savePresetFarmersHg({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      queryParams.dataVersion = getDataVersion(rootState)
      ApiService.post('PresetFarmerAndHGs', queryParams, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  removePresetFarmersHg({ rootState }, queryParams) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`PresetFarmerAndHGs/${queryParams.id}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
